import React from 'react';

import CustomerReview from '../../components/customer-reviews';

const Client = () => {

    return (
        <div id='client'>
            <CustomerReview />
        </div>
    );
};
export default Client;