import React from 'react';
import CarOffers from '../../components/car-offers';

const Vehicles = () => {

    return (
        <div id='vehicles'>
            <CarOffers />
        </div>
    );
};
export default Vehicles;